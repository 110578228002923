@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;900&display=swap');

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;

  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

 /* ############# The CSS vars we will use throughout †he project ############# */
:root {
  /* ####### BLACK / GOLD COLORS ####### */
  /* --color-bg: #d0981f; */
  /* --color-bg-variant: #3c3c3c;
  --color-primary: #bfbfbf;
  --color-primary-variant: #1f1f38;
  --color-black: black;
  --color-grey: grey; */


  /* ####### BLUE COLORS #######  */
  --color-bg: #000ea6;
  --color-bg-variant: #8b8ad7bf;
  --color-primary: #afbcff;
  --color-primary-variant: #0000a4;
  --color-black: black;
  --color-grey: grey;

  --transition: all 400ms ease;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar{
  display: none;
}

body {
  font-family: "Nunito", sans-serif;
  background: var(--color-bg-variant);
  color: white;
  line-height: 1.7;
  background-image: url(../src/images/background14.jpeg);
}

/* ############# GENERAL STYLES ############# */
.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

section {
  margin-top: 8rem;
}

section > h2, section > h5 {
  text-align: center;
  color: white;
}

section > h2 {
  color: var(--color-primary);
  margin-bottom: 3rem;
}

.text-light {
  color: var(--color-light);
  transition: var(--transition);
}

a:hover {
  color: white;
  transition: var(--transition);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 2px solid var(--color-bg-variant);
  /* background-color: var(--color-black); */
}

.btn:hover {
  background: white;
  color: var(--color-bg);
  border-color: transparent;
  border: 2px solid var(--color-bg-variant);
  transition: var(--transition);
}

.btn-primary {
  background-color: var(--color-bg-variant);
  color: var(--color-primary);
  transition: var(--transition);
}

img {
  display: block;
  width: 100%;
  object-fit: cover ;
}

/* ############# MEDIA QUERIES (MEDIUM DEVICES) ############# */
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 6rem;
  }
}

/* ############# MEDIA QUERIES (SMALL DEVICES) ############# */
@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }

  section > h2 {
    margin-bottom: 2rem;
  }
}
